.login {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login__container {
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: #f3f3f3 ;
  padding: 30px;
  border-radius: 0.5rem;
  box-shadow: 4px 10px 5px 0px rgba(82,82,82,0.75);
-webkit-box-shadow: 4px 10px 5px 0px rgba(82,82,82,0.75);
-moz-box-shadow: 4px 10px 5px 0px rgba(82,82,82,0.75);
}

.login__textBox {
  padding: 10px;
  font-size: 18px;
  margin-bottom: 10px;
}

.login__btn {
  padding: 10px;
  font-size: 18px;
  margin-bottom: 10px;
  border: none;
  color: white;
  background-color: black;
}

.login__google {
  background-color: #4285f4;
}

.login div {
  margin-top: 7px;
}
