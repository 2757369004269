.grid-container {
    display: grid;grid-template-columns: repeat(2, 1fr); grid-template-rows: repeat(3, auto); 
    gap: 10px;padding: 10px;
}

.grid-item {
   font-size: 30px;text-align: center;display: flex;flex-direction: column;
    align-items: center;justify-content: center;padding: 10px;
}

.grid-item img {max-width: 100%;max-height: 100%;border-radius: 15px;cursor: pointer;}

.grid-item.clicked img {border: 4px solid rgb(0, 0, 0);}

.grid-item h5 {color: #222;margin-top: 10px;font-size: 20px;font-weight: 600;}

.banner img {max-width: 100%;max-height: 100%;} 

