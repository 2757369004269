html{background-color: lavender!important}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative; 
  overflow-x: hidden;
  min-height: 100vh!important;
  background-color: lavender;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}





::-webkit-scrollbar {width: 0.4em;}
::-webkit-scrollbar-track {box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);}
::-webkit-scrollbar-thumb {background-color: rgba(0, 0, 0, 0.549);outline: 0.3px solid rgba(0, 0, 0, 0.281);}


