.flashcards {
    height: 100%;
    width: 100%;
    place-items: center center;
    background: lavender;
    overflow: hidden;
    display: grid;
  }
  
  .flashcards__container {
    display: flex;
    align-items: center;
    perspective: 150px;
    height: 100%;
    margin: 50px 50px auto;
  }
  
  .card {
    margin-left: -70px;
    position: relative;
    height: 180px;
    width: 100px;
    background-image: url("https://www.blackwhispers.com/image/tarotcard.jpg");
    background-size: cover;
    border-radius: 15px;
    cursor: pointer;
    transform-origin: left center; /* Set the transformation origin to the left */
    transition: transform 0.35s ease-in-out; /* Add a transform transition for smooth animation */
  }
  
  .card:not(:first-of-type) {
    margin-left: -80px; /* Adjust the negative margin to control the overlap */
  }
  
  @media (max-width: 768px) {
    .card{
      width: 90px;
      height: 140px;
      background: violet;
    }
    .card:not(:first-of-type) {
      margin-left: -80px; /* Reduce margin for smaller screens */
    }
  }
  
  @media (max-width: 480px) {
    .card{
      width: 88px;
      height: 140px;
      background: pink;
    }
    .card:not(:first-of-type) {
      margin-left: -83px;/* Further reduce margin for very small screens */
    }
  }

  
 
  