.preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    display: flex;
  flex-direction: column;
  align-items: center;
  }
  
  .intro-container {
    display: flex;
    justify-content: center;
  }
  
  #introtext {
    margin-top: 10px;
    text-align: center;
    font-size: 1.5rem;
    font-weight: 800;
  }
  
  .spinner img {
    /* Add styles for your spinner animation */
  animation: spinlogo 2s linear infinite;
  width:65px;
  height:65px;
  margin:20px;
  }

  @keyframes spinlogo {
    0%{
       transform: rotate(0deg);
      }
   100%{
       transform: rotate(360deg);
      }
   }
   
  